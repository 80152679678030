<template>
  <v-row
    v-if="taskData !== null"
    justify="center"
    class="fill-height text-center"
  >
    <v-col cols="12">
      <h1>{{ $t('taskDetail.title') }}</h1>
    </v-col>
    <v-col cols="12">
      <h1 class="mb-4">
        {{ taskData.carError.carType.subType ? taskData.carError.carType.subType: taskData.carError.carType.name }}
      </h1>
      <h2 class="mb-4">
        Status: {{ status }}
      </h2>
      <p>{{ $t('taskDetail.description') }}: {{ taskData.carError.description }}</p>
    </v-col>
    <v-col cols="12">
      <information-tiles
        v-if="taskData !== null"
        :creation-data="userTile"
      />
    </v-col>
    <v-col cols="12">
      <v-btn
        v-if="!taskData.startedAt"
        x-large
        color="primary"
        @click="startTask"
      >
        {{ $t('taskDetail.startTask') }}
      </v-btn>
      <v-btn
        v-if="taskData.startedAt && !taskData.finishedAt"
        x-large
        color="primary"
        @click="endTask"
      >
        {{ $t('taskDetail.endTask') }}
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-btn
        v-if="taskData.startedAt && !taskData.activePause && !taskData.finishedAt"
        x-large
        color="primary"
        @click="startPause"
      >
        {{ $t('taskDetail.startPause') }}
      </v-btn>
      <v-btn
        v-if="taskData.activePause && taskData.startedAt && !taskData.finishedAt"
        x-large
        color="primary"
        @click="finishPause"
      >
        {{ $t('taskDetail.endPause') }}
      </v-btn>
    </v-col>
    <v-col sm="4">
      <v-select
        v-model="amount"
        :items="items"
        :label="$t('taskDetail.label')"
      />
    </v-col>
    <v-col cols="12">
      <v-btn
        x-large
        color="primary"
        @click="askForHelp"
      >
        {{ $t('taskDetail.help') }}
      </v-btn>
    </v-col>
    <v-row
      align="center"
      justify="center"
    >
      <v-col sm="3">
        <v-alert
          v-if="error"
          max-width="400"
          type="error"
          dismissible
        >
          {{ $t('taskDetail.wrong') }}
        </v-alert>
        <v-alert
          v-if="success"
          style="cursor: pointer;"
          max-width="400"
          type="success"
          dismissible
          @click="goToError"
        >
          {{ $t('taskDetail.great') }}
        </v-alert>
        <v-alert
          v-if="helpSuccess"
          style="cursor: pointer;"
          max-width="400"
          type="success"
          dismissible
          @click="goToError"
        >
          {{ $t('taskDetail.found') }}
        </v-alert>
      </v-col>
    </v-row>
    <!-- <v-col cols="12">
      <error-history-table
        v-if="previousMachineErrors"
        :table-name="$t('taskDetail.table.name')"
        :headers="headers"
        :table-data="previousMachineErrors"
      />
    </v-col> -->
  </v-row>
</template>

<script>
import InformationTiles from './InformationTiles'
// import ErrorHistoryTable from './ErrorHistoryTable'

export default {
  name: 'ErrorDetail',
  components: {
    InformationTiles
    // ErrorHistoryTable
  },
  data () {
    return {
      taskData: null,
      success: false,
      error: false,
      helpSuccess: false,
      amount: 1,
      items: [1, 2, 3],
      previousMachineErrors: null,
      headers: [
        { text: this.$t('taskDetail.table.errorName'), sortable: false, value: 'errorCategory.name' },
        { text: this.$t('taskDetail.table.errorMainCategory'), value: 'errorCategory.mainCategory' },
        { text: this.$t('taskDetail.table.errorCreationTime'), value: 'createdAt' },
        { text: this.$t('taskDetail.table.neededMaintainer'), value: 'neededMaintainers' }
      ]
    }
  },
  computed: {
    userTile () {
      if (!this.taskData.user) return {}
      return {
        kind: 'Informácie používateľa',
        icon: 'mdi-account',
        primaryText: this.$t('taskDetail.name') + ': ' + this.taskData.user.firstName + ' ' + this.taskData.user.lastName,
        secondaryText: this.$t('taskDetail.description') + ': ' + this.taskData.user.email
      }
    },
    status () {
      if (this.taskData.status === 'PAUSED') {
        return this.$t('taskDetail.paused')
      } else if (!this.taskData.startedAt) {
        return this.$t('taskDetail.notStarted')
      } else if (this.taskData.startedAt && !this.taskData.finishedAt) {
        return this.$t('taskDetail.inProgress')
      } else {
        return this.$t('taskDetail.done')
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const { data } = await this.$axios.get(`/car-tasks/${this.$route.params.id}/?select=*,user(firstName,lastName,email),carError(*),description`)
        const res2 = await this.$axios.get(`car-errors/?filter.id=${data.carError.carTypeId}`)
        this.taskData = data
        this.previousCarErrors = res2.data
      } catch (err) {
        console.error(err)
      }
    },
    async startTask () {
      try {
        this.error = false
        const sendData = {
          startedAt: new Date().toISOString()
        }
        const { data } = await this.$axios.post(`/car-tasks/${this.$route.params.id}`, sendData)
        Object.assign(this.taskData, data)
      } catch (err) {
        this.error = true
        console.log(err)
      }
    },
    async endTask () {
      try {
        this.success = false
        this.error = false
        const sendData = {
          finishedAt: new Date().toISOString()
        }
        const { data } = await this.$axios.post(`/car-tasks/${this.$route.params.id}`, sendData)
        this.success = true
        Object.assign(this.taskData, data)
      } catch (err) {
        this.error = true
        console.log(err)
      }
    },
    async startPause () {
      try {
        const { data } = await this.$axios.post(`/car-tasks/${this.$route.params.id}/start-pause?select=activePause`)
        Object.assign(this.taskData, data)
      } catch (err) {
        console.error(err)
      }
    },
    async finishPause () {
      try {
        const { data } = await this.$axios.post(`/car-tasks/${this.$route.params.id}/finish-pause?select=activePause`)
        Object.assign(this.taskData, data)
      } catch (err) {
        console.error(err)
      }
    },
    goToError () {
      this.$router.push({ name: 'ErrorDashboardCar' })
    },
    async askForHelp () {
      try {
        this.helpSuccess = false
        await this.$axios.post(`/car-tasks/${this.$route.params.id}/call-help`, {
          neededMaintainers: this.amount
        })
        this.helpSuccess = true
        this.$router.push({ name: 'ErrorDashboardCar' })
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>
